<template>
    <svg
        class="logo"
        width="778"
        height="743"
        viewBox="0 0 778 743"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M388.909 742.872L777.817 353.964L424.056 0.202599L478.809 132.737L700.036 353.964L388.909 665.091L77.7817
            353.964L299.507 129.121L353.964 0L0 353.964L388.909 742.872Z"
            fill="currentColor"
        />
        <path
            d="M388.909 665.091L353.964 0L299.507 129.121L388.909 665.091Z"
            fill="currentColor"
        />
        <path
            d="M424.056 0.202599L388.909 665.091L478.809 132.737L424.056 0.202599Z"
            fill="currentColor"
        />
    </svg>
</template>