<template>
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.26198 0.385742C4.14474 0.385742 0 4.46101 0 9.48718C0 13.5092 2.65356 16.9199 6.33288 18.1224C6.79598
            18.2081 6.96578 17.9264 6.96578 17.6848C6.96578 17.4687 6.95806 16.896 6.9542 16.1373C4.37783 16.6861
            3.83446 14.9162 3.83446 14.9162C3.41304 13.8658 2.80406 13.5848 2.80406 13.5848C1.96508 13.0206 2.8689
            13.0322 2.8689 13.0322C3.79896 13.0955 4.28753 13.97 4.28753 13.97C5.11339 15.3616 6.4556 14.9595 6.98508
            14.7271C7.06844 14.1382 7.30693 13.7377 7.57167 13.51C5.51474 13.2823 3.35284 12.4996 3.35284 9.0125C3.35284
            8.01916 3.71174 7.20719 4.30605 6.57043C4.20185 6.34042 3.88926 5.415 4.38709 4.16154C4.38709 4.16154
            5.16278 3.91764 6.93414 5.09469C7.6751 4.89247 8.46236 4.79213 9.24963 4.7875C10.0369 4.79213 10.8242
            4.89247 11.5651 5.09469C13.3249 3.91764 14.1006 4.16154 14.1006 4.16154C14.5984 5.415 14.2858 6.34042
            14.1932 6.57043C14.7837 7.20719 15.1426 8.01916 15.1426 9.0125C15.1426 12.5089 12.9776 13.2784 10.9168
            13.5022C11.241 13.7755 11.542 14.3335 11.542 15.1864C11.542 16.4043 11.5304 17.383 11.5304 17.6786C11.5304
            17.9171 11.6925 18.2019 12.1672 18.1109C15.8727 16.9161 18.524 13.503 18.524 9.48718C18.524 4.46101 14.3769
            0.385742 9.26198 0.385742Z"
            fill="currentColor"
        />
    </svg>
</template>